@import "FRONTEND/css/_variables.scss";


.settings-page {
  position: relative;
  height: calc(100% - 48px);
  margin: 24px;

  overflow: hidden;


  .settings-content {
    height: calc(100% - 57px);
    margin-bottoM: 12px;

    h1 {
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: 500;
    }

    .settings-area {
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      padding: 20px 14px 14px;
      position: relative;

      h2 {
        position: absolute;
        top: -13px;
        font-weight: 500;
        font-size: 1.2rem;
        padding: 0 4px;
        background-color: #fff;
      }
    }

    @include rudolf-scrollbars;
  }

  .settings-bottom {

  }


}


