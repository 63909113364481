@import "FRONTEND/css/_variables.scss";


.map-settings-container {
    position: absolute;

    left: 5px;

    width: 25px;
    height: 25px;

    z-index: 10;

    padding-top: 2px;
    padding-left: 2px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;

    background-color: #fff;
    border-radius: 2px;

    box-shadow: 1px 1px 3px 0px #adadad;

    .map-settings-dropdown {
      padding: 3px 10px 3px 10px;
      width: 250px;
      font-size: 0.857rem;
    }
}

div.google-map {
  border-radius: 8px;
  height: 100%;
}

