@import "FRONTEND/css/_variables.scss";


.search-container {
  height: 100%;
  padding: 20px;
  position: relative;

  .search-result-box {
    background-color: #fff;
    filter: drop-shadow(0px 3px 6px #c7c7c7);
    padding: 10px;
    margin: 0 0 15px 0;
    border-radius: 6px;
    font-size: 0.929rem;

    .contact-container {
      display: flex;
      column-gap: 15px;
      flex-wrap: nowrap;

      .contact-info {
        flex-grow: 1;
      }

      .contact-linkedin {
        margin: 0;
      }
    }
  }
}

