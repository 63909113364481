//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//


$body-size:                     14px;
$family-sans-serif:             "Montserrat", sans-serif;
$text:                          #3b3b3b;
$primary:                       #D01D19;
$grey:                          #959BA3;


// Layout breakpoints
$tablet:                        768px;
$desktop:                       992px;
$widescreen:                    1200px;
$fullhd:                        1408px;

$column-gap: 12px;

$table-sticky-header-height: 100%;


// vue-toastification options
$vt-font-family:                $family-sans-serif; 

$vt-color-default:              #fff;
$vt-text-color-default:         $text;

$vt-color-success:              #b0eeac;
$vt-text-color-success:         $text;

$vt-color-warning:              #fff281;
$vt-text-color-warning:         $text;


@mixin media-low-res-or-big-dpi {
    @media screen and (max-width: $fullhd - 1), (-moz-min-device-pixel-ratio: 1.25),
           screen and (max-width: $fullhd - 1), (-o-min-device-pixel-ratio: 1.25),
           screen and (max-width: $fullhd - 1), (-webkit-min-device-pixel-ratio: 1.25),
           screen and (max-width: $fullhd - 1), (min-device-pixel-ratio: 1.25)
    {
        @content;
    }
}


@mixin media-high-res-or-low-dpi {
    @media screen and (min-width: $fullhd) and (-moz-max-device-pixel-ratio: 1.249),
           screen and (min-width: $fullhd) and (-o-max-device-pixel-ratio: 1.249),
           screen and (min-width: $fullhd) and (-webkit-max-device-pixel-ratio: 1.249),
           screen and (min-width: $fullhd) and (max-device-pixel-ratio: 1.249)
    {
        @content;
    }
}


@mixin media-huge-dpi {
    @media screen and (-moz-min-device-pixel-ratio: 1.5),
           screen and (-o-min-device-pixel-ratio: 1.5),
           screen and (-webkit-min-device-pixel-ratio: 1.5),
           screen and (min-device-pixel-ratio: 1.5)
    {
        @content;
    }
}


@mixin rudolf-scrollbars {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #f6f6f6;
    }
    
    @include media-low-res-or-big-dpi {
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #f6f6f6;
        }
    }

    @include media-high-res-or-low-dpi {
        &::-webkit-scrollbar {
            width: 12px;
            height: 12px;
            background-color: #f6f6f6;
        }
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #959ba3;
    }
}
