@import "FRONTEND/css/_variables.scss";


.topnav-buttons {
  display: flex;
  align-items: center;

  .advanced-filter-tool { z-index: 1700; }
  .rnotifications { z-index: 1699; }

  .advanced-filter-tool {
    margin-right: 10px;
  }
}

