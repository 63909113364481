@import "FRONTEND/css/_variables.scss";


.new-question-form {
  margin-right: 12px;

  .slider-min-max-field {
    p.help.is-danger { margin-top: 16px; }
  }
}

.questions-form-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    row-gap: 24px;

    align-items: center;

    > .form-name {
        flex-grow: 1;
    }

    > .edit-form-name {
        flex-basis: 30%;
        min-width: 250px;
        margin-right: 30px;
    }


    .change-form-name-button {
        cursor: pointer;
        padding: 8px 15px;
    }

    .change-form-name-button:hover {
        border-radius: 18px;
        background-color: #f5f5f5;
    }
}


.questions-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    margin-top: 30px;
    justify-content: flex-start;

    .button.icon-button.is-light {
      margin-top: 4px;
    }

    .question {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;

        flex: 33.33%;
        max-width: 33.33%;
        min-width: 390px;

        .public-questionnare-entry {
          background-color: #eaeaea;
          border-radius: 8px;
          padding: 8px;

          .public-questionnare-entry-title {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 0.857rem;
          }
        }

        .question-container {
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            font-weight: 500;

            padding: 16px 14px 14px 14px;

            width: 100%;

        }

    }

}


