@import "FRONTEND/css/_variables.scss";


@include media-low-res-or-big-dpi {
  .overview-columns { column-gap: 16px; }
  .overview-columns .overview-left { width: calc(66.6% - 8px); }
  .overview-columns .overview-right { width: calc(33.4% - 8px); }

  .overview-columns .overview-left .rudolfbox.personal-target {
    height: 130px;
    margin-bottom: 16px;
  }

  .overview-columns .overview-left .rudolfbox.googlemap {
      height: calc(100% - 130px - 170px - 16px - 16px);
      margin-bottom: 16px;
  }

  .overview-columns .overview-right .rudolfbox.kpi-sales { margin-bottom: 16px; }
  .overview-columns .overview-right .rudolfbox.upcoming-tasks { height: calc(100% - 210px - 16px); }
}

@include media-high-res-or-low-dpi {
  .overview-columns { column-gap: 24px; }
  .overview-columns .overview-left { width: calc(66.6% - 12px); }
  .overview-columns .overview-right { width: calc(33.4% - 12px); }

  .overview-columns .overview-left .rudolfbox.personal-target {
    height: 135px;
    margin-bottom: 24px;
  }

  .overview-columns .overview-left .rudolfbox.googlemap {
      height: calc(100% - 135px - 170px - 24px - 24px);
      margin-bottom: 24px;
  }

  .overview-columns .overview-right .rudolfbox.kpi-sales { margin-bottom: 24px; }
  .overview-columns .overview-right .rudolfbox.upcoming-tasks { height: calc(100% - 210px - 24px); }
}

@include media-huge-dpi {
  .overview-columns { column-gap: 10px; }
  .overview-columns .overview-left { width: calc(66.6% - 5px); }
  .overview-columns .overview-right { width: calc(33.4% - 5px); }

  .overview-columns .overview-left .rudolfbox.personal-target {
    height: 115px;
    margin-bottom: 10px;
  }

  .overview-columns .overview-left .rudolfbox.googlemap {
      height: calc(100% - 115px - 170px - 10px - 10px);
      margin-bottom: 10px;
  }

  .overview-columns .overview-right .rudolfbox.kpi-sales { margin-bottom: 10px; }
  .overview-columns .overview-right .rudolfbox.upcoming-tasks { height: calc(100% - 210px - 10px); }
}


.overview-columns {
  display: flex;
  height: 100%;

  .overview-left {
    flex: 66.6%;
    height: 100%;

    .rudolfbox.personal-target {
      padding: 10px;

      .personal-target-top {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .personal-target-title {
          flex-grow: 0;
          margin-right: 20px;
        }

        .personal-target-user-select {
          flex-grow: 0;
        }

        .personal-target-settings-dropdown {
          flex-grow: 1;
          text-align: right;

          .personal-target-settings {
            width: 250px;

            text-align: left;

            padding: 0.3rem 0.8rem;
          }
        }
      }
    }

    .rudolfbox.task-pipeline {
      height: 170px;
      padding: 10px;
      width: 100%;
    }
  }

  .overview-right {
    flex: 33.4%;
    height: 100%;

    .rudolfbox.kpi-sales {
      height: 210px;
      padding: 10px;

      .month-select {
        width: 150px;
        margin-left: 25px;

        .button {
          padding: calc(.5em - 1px) 1em;
          padding-left: 9px;
          height: 2em;
        }

        .button.is-small {
          font-size: 1rem;
        }

        .button.is-select {
          font-weight: 600;
        }
      }
    }

    .rudolfbox.upcoming-tasks {
      padding: 10px;

      .contacts-tab-header-number {
        text-align: center;
        color: $text;
        font-size: 1.714rem;
        font-weight: 600;
        line-height: 2.2rem;
      }

      .contacts-tab-header-subtitle {
        color: $grey;
      }

      .b-tabs {
        height: calc(100% - 4.2rem);
        overflow-y: hidden;
        margin-bottom: 0;

        .tab-item {
          height: 100%;

          .meetings-list {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            border: 1px solid #d9d9d9;
            border-radius: 3px;
            padding: 7px;

            @include rudolf-scrollbars;

            .meetings-daily {
              margin-bottom: 0.714rem;

              .meetings-date { margin-bottom: 5px; }

              .meetings-daily-list {

                .meeting-daily-item {
                  align-items: flex-start;
                  display: flex;
                  padding: 5px 10px 5px 20px;
                  position: relative;
                  margin: 0 -7px;

                  &:hover { background-color: #eaeaea; }

                  &.selected {
                    border-top: 1px solid #959BA3;
                    border-bottom: 1px solid #959BA3;
                    background-color: #f6f6f6;
                    padding: 4px 10px;
                  }

                  .meeting-text {
                    margin-top: -1px;
                    flex-grow: 1;
                  }
                }
              }
            }
          }


          .call-list {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            border: 1px solid #d9d9d9;
            border-radius: 3px;
            padding: 5px 0;

            @include rudolf-scrollbars;

            .call-list-item {
              display: flex;
              padding: 2px 5px;

              &:hover { background-color: #f6f6f6; }

              .call-list-dot { margin-top: -1px; }

              .call-list-info {
                flex-grow: 1;
              }
            }
          }


          .todo-list {
            overflow-x: hidden;
            overflow-y: auto;
            border: 1px solid #D9D9D9;
            border-radius: 4px;
            padding: 7px;
            height: 100%;

            @include rudolf-scrollbars;

            .todo-daily {
              margin-bottom: 0.714rem;

              .todo-daily-list {

                .todo-daily-item {
                  align-items: flex-start;
                  display: flex;
                  margin-left: -7px;
                  margin-right: -7px;
                  padding: 5px 2px;
                  position: relative;

                  &:hover { background-color: #f6f6f6; }

                  .todo-dot { margin-top: -2px; }

                  .todo-text {
                    margin-top: -1px;
                    flex-grow: 1;
                  }
                }
              }
            }
          }


        }



      }

      .go-to-activities-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .activities-link {
          cursor: pointer;
          display: flex;
          flex-grow: 0;
          padding: 5px 10px;
          border-radius: 4px;
          margin-top: 4px;

          cursor: pointer;

          &:hover { background-color: #f6f6f6; }
        }

      }
    }
  }
}


