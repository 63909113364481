@import "FRONTEND/css/_variables.scss";


.localization-grid {
  display: grid;
  grid-template-columns: 300px 1fr;
  min-height: 100%;
  height: 100%;
  grid-auto-rows: 100%;

  .localization-left {
    height: 100%;
    border-right: 1px solid #F6F6F6;

    .top-area {
      height: 90px;
      padding: 10px;
      border-bottom: 1px solid #f6f6f6;

      input.add-localization-input {
        letter-spacing: 0.56px;
        font-weight: 400 !important;
      }
    }

    .localizations-list {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100% - 110px);
      margin: 10px 0;

      @include rudolf-scrollbars;

      .localization {
        display: flex;
        padding: 7px 10px;
        border-bottom: 1px solid #d9d9d9;

        &:hover {
          background-color: #f6f6f6;
          cursor: pointer;
        }

        &.selected {
          background-color: #eaeaea;
        }

        &:last-child { border-bottom: none; }

        .localization-name {
          flex-grow: 1;
        }

        .localization-controls {
          display: flex;
        }
      }

    }
  }

  .localization-right {
    margin: 10px 0;
    padding: 0 0 0 15px;
    height: calc(100% - 20px);
  }
}


.modal.import-localization {
  overflow: visible;

  .modal-content .modal-inner {
    overflow: visible;
  }

  .import-file {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .import-to {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 15px;

    .import-to-text {
      font-size: 1.25rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

