@import "FRONTEND/css/_variables.scss";


.activities-top-columns {
  display: flex;
  height: calc(50% - 24px);
  margin-bottom: 24px;
  column-gap: 24px;

  .activities-map {
    flex: 66.6%;
    position: relative;
    height: 100%;
  }

  .activities-meetings {
    flex: 33.4%;
    padding: 12px;

    @include media-low-res-or-big-dpi {
      .meetings-top { height: 96px; }
      .meetings-list { height: calc(100% - 29px); }
    }

    @include media-high-res-or-low-dpi {
      .meetings-top { height: 102px; }
      .meetings-list { height: calc(100% - 32px); }
    }

    @include media-huge-dpi {
      .meetings-top { height: 90.5px; }
      .meetings-list { height: calc(100% - 26px); }
    }

    .meetings-top {
      height: 2.8rem;

      position: relative;

      margin: -11px -11px 2px -11px;

      padding: 10px;

      &:hover {
        background-color: #f6f6f6;
        border-radius: 8px;
      }
    }

    .meetings-list {
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: 5px;

      @include rudolf-scrollbars;

      .meetings-daily {
        margin-bottom: 0.714rem;

        .meetings-date { margin-bottom: 5px; }

        .meetings-daily-list {

          .meeting-daily-item {
            align-items: flex-start;
            display: flex;
            padding: 5px 10px;
            position: relative;

            &:hover { background-color: #eaeaea; }

            &.selected {
              border-top: 1px solid #959BA3;
              border-bottom: 1px solid #959BA3;
              background-color: #f6f6f6;
              padding: 4px 10px;
            }

            .meeting-text {
              margin-top: -1px;
              flex-grow: 1;
            }
          }
        }
      }
    }

  }
}

.activities-bottom-columns {
  display: flex;
  height: 50%;
  column-gap: 24px;

  .activities-bottom-left {
    flex: 66.6%;
    display: flex;
    column-gap: 24px;
    height: 100%;

    .activities-notifications,
    .activities-contacts {
      flex: 50%;
      padding: 12px;
      height: 100%;
    }

    .activities-notifications {
      .rnotifications-top {
        height: 2.8rem;

        position: relative;

        margin-left: -11px;
        margin-right: -11px;
        margin-top: -11px;
        margin-bottom: 2px;

        padding: 10px 10px 10px 10px;

        &:hover {
          background-color: #f6f6f6;
          border-radius: 8px;
        }

        .rnotifications-top-buttons {
          display: flex;
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }

      .rnotifications-list {
        height: calc(100% - 2.3rem);
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 6px;

        .rnotification {
          display: flex;
          background-color: #f6f6f6;
          padding: 10px;
          border-bottom: 1px solid #959BA3;

          z-index: 1;

          cursor: pointer;

          &.rnotification-list-move,
          &.rnotification-list-enter-active,
          &.rnotification-list-leave-active {
            transition: all 0.5s ease;
          }
          &.rnotification-list-enter-from,
          &.rnotification-list-leave-to {
            opacity: 0;
            transform: translateX(30px);
          }
          &.rnotification-list-leave-active {
            position: absolute;
          }

          &.read:hover,
          &:hover { background-color: #eaeaea; }

          &:last-of-type { border-bottom: none; }

          .rnotification-text {
            flex-grow: 1;
            font-weight: 500;

            .rnotification-time {
              font-size: 0.857rem;
              color: $grey;
              font-weight: 500;

              sup {
                font-size: .7em;
                position: relative;
                top: 1.5px;
                vertical-align: top;
              }
            }
          }

          &.read {
            background-color: #fff;

            .rnotification-text { font-weight: 400; }
          }

          .rnotification-buttons {
            display: flex;

            z-index: 5;

            .rnotification-mark-as-read {
            }

            .rnotification-delete {

            }
          }

        }

        @include rudolf-scrollbars;
      }
    }

    .activities-contacts {
      .contacts-top {
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;

        cursor: pointer;

        height: 2.8rem;
        margin: -11px -11px 2px;
        padding: 10px;
        position: relative;

        &:hover {
          background-color: #f6f6f6;
          border-radius: 8px;
        }
      }

      .contacts-tab-header-number {
        text-align: center;
        color: $text;
        font-size: 1.714rem;
        font-weight: 600;
        line-height: 2.2rem;
      }

      .contacts-tab-header-subtitle {
        color: $grey;
      }

      .b-tabs {
        height: calc(100% - 2.3rem);
        overflow-y: hidden;

        .tab-item {
          height: 100%;

          .call-list,
          .contact-list {
            height: calc(100% - 4.5rem);
            overflow-y: auto;
            overflow-x: hidden;
            border: 1px solid #d9d9d9;
            border-radius: 3px;
            padding: 5px 0;

            @include rudolf-scrollbars;

            .call-list-item,
            .contact {
              display: flex;
              padding: 2px 5px;

              &:hover { background-color: #eaeaea; }

              &.selected {
                border-top: 1px solid #959BA3;
                border-bottom: 1px solid #959BA3;
                background-color: #f6f6f6;
                padding: 1px 5px;
              }

              .call-list-info,
              .contact-info {
                flex-grow: 1;
              }

              .contact-menu {
                .contact-dropdown-content {
                  .contact-dropdown-item {
                    display: flex;
                    padding: 3px 5px;

                    &:hover {
                      background-color: #f6f6f6;
                    }
                  }
                }
              }
            }
          }

          @include media-low-res-or-big-dpi {
            .call-list { height: calc(100% - 4.55rem); }
            .contact-list { height: calc(100% - 4.55rem); }
          }

          @include media-huge-dpi {
            .call-list { height: calc(100% - 4.55rem); }
            .contact-list { height: calc(100% - 4.55rem); }
          }

          .call-list-pagination,
          .contacts-pagination {
            display: flex;

            .pagination-left {
              flex-grow: 1;

              .call-list-items-per-page,
              .contacts-per-page {
                display: flex;
                align-items: flex-start;
                margin-top: 3px;
              }
              
              .call-list-items-per-page-dropdown,
              .contacts-per-page-dropdown {
                width: auto;
                margin-right: 5px;

                .dropdown {
                  .button.is-small.is-select {
                    height: 1.3rem;
                  }
                }
              }

              .call-list-items-per-page-text,
              .contacts-per-page-text {
                font-weight: 500;
                font-size: 0.929rem;
                line-height: 1.2rem;
              }

              @include media-low-res-or-big-dpi {
                .call-list-items-per-page-text { display: none; }
                .contacts-per-page-text { display: none; }
              }

              @include media-huge-dpi {
                .call-list-items-per-page-text { display: none; }
                .contacts-per-page-text { display: none; }
              }

              // Tighter b-dropdown menu here
              .button { padding: calc(.5em - 1px) calc(.75em - 1px); }
              .button.is-select {
                justify-content: flex-end;
                border-radius: 4px;
              }
              .button.is-select span:first-child { text-overflow: initial; }
            }

            .pagination-right {
              flex-grow: 1;

              font-weight: 500;
              font-size: 0.929rem;
              text-transform: uppercase;

              display: flex;
              justify-content: flex-end;
              align-items: center;
              flex-wrap: nowrap;

              > div {
                width: 25px;
                text-align: center;
              }

              > div.pagination-button {
                border-radius: 4px;
                height: 1.3rem;
                line-height: 1.2rem;
              }

              > div.pagination-button.prev,
              > div.pagination-button.next, {
                width: 25px;
              }

              > div.pagination-button:hover,
              > div.pagination-button.selected {
                background-color: #eaeaea;
                cursor: pointer;
              }

            }
          }

        }



      }

    }
  }

  .activities-todo {
    flex: 33.4%;
    padding: 12px;

    @include media-low-res-or-big-dpi {
      .todo-top { height: 96px; }
      .todo-list { height: calc(100% - 86px); }
    }

    @include media-high-res-or-low-dpi {
      .todo-top { height: 102px; }
      .todo-list { height: calc(100% - 92px); }
    }

    @include media-huge-dpi {
      .todo-top { height: 90.5px; }
      .todo-list { height: calc(100% - 79.5px); }
    }

    .todo-top {
      .todo-top-title {
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;

        cursor: pointer;

        height: 2.8rem;
        margin: -11px -11px 2px;
        padding: 10px;
        position: relative;

        &:hover {
          background-color: #f6f6f6;
          border-radius: 8px;
        }
      }

      input.product-search-input {
        letter-spacing: 0.56px;
        font-weight: 400 !important;
      }

      button.button.icon-button.is-large.is-light {
        padding: 0 6px;
      }

      @media screen and (max-width: 1024px) {
        .show-dropdown-text {
          display: none;
        }
      }
    }

    .todo-list {
      overflow-x: hidden;
      overflow-y: auto;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      padding: 7px;

      @include rudolf-scrollbars;

      .todo-daily {
        margin-bottom: 0.714rem;

        .todo-daily-list {

          .todo-daily-item {
            display: flex;
            align-items: flex-start;

            position: relative;

            margin-left: -7px;
            margin-right: -7px;
            padding: 5px 0;

            cursor: pointer;

            &:hover { background-color: #eaeaea; }

            &.selected {
              border-top: 1px solid #959BA3;
              border-bottom: 1px solid #959BA3;
              background-color: #f6f6f6;
              padding: 4px 0;
            }

            .todo-dots {
              margin-right: 15px;
              width: 21px;

              .todo-dropdown-content {

                .todo-dropdown-item {
                  display: flex;
                  padding: 3px 5px;

                  &:hover {
                    background-color: #f6f6f6;
                  }
                }
              }
            }

            .todo-checkbox {
              padding-top: 2px;
              margin-right: -7px;
              width: 34px;
            }

            .todo-text {
              margin-top: -1px;
              flex-grow: 1;
            }
          }
        }
      }
    }

  }

}

@media screen and (max-width: $tablet - 1) {
  .activities-top-columns {
    display: block;
    margin: 1rem 0;

    .rudolfbox {
      border-radius: 0;
    }

    .activities-meetings {
      .meetings-top {
        cursor: default;

        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  .activities-bottom-columns {
    flex-direction: column;
    gap: 1rem;

    .rudolfbox {
      border-radius: 0;
    }

    .activities-bottom-left {
      .activities-contacts {
        height: 500px;

        .contacts-top {
          cursor: default;

          &:hover {
            background-color: #fff;
          }
        }
      }
    }

    .activities-todo {
      height: auto;

      .todo-top {
        .todo-top-title {
          cursor: default;

          &:hover {
            background-color: #fff;
          }
        }
      }

      .todo-list {
        height: 300px;
        margin-top: 13px;
      }
    }

  }
}

.activities-page-box {
  height: 100%;
  padding: 12px;
}


.rudolfbox.active {
    border: 1px solid #959BA3;
    box-sizing: border-box;
    padding: 11px !important;
}

