@import "FRONTEND/css/_variables.scss";


.b-sidebar {
    margin-top: 4px; // Gap between top bar and sidebar
    height: 100%;


    .sidebar-content {
        background-color: #fff !important;
        box-shadow: none !important;
        padding-top: 14px;
        justify-content: space-between;

        @include rudolf-scrollbars;

        figure.overview-button > figcaption { padding-top: 60px; }
        figure.manage-leads-button > figcaption { padding-top: 60px; }
        figure.activities-button > figcaption { padding-top: 60px; }

        @include media-low-res-or-big-dpi {
            figure figcaption { letter-spacing: -0.03em; }
            figure.overview-button figcaption { padding-top: 55px !important; }
            figure.manage-leads-button figcaption { padding-top: 55px !important; }
            figure.activities-button figcaption { padding-top: 55px !important; }
            figure.setup-button figcaption { padding-top: 55px !important; }
            figure.outlook-button figcaption { padding-top: 53px !important; }
            figure.account-button figcaption { padding-top: 55px !important; }

            .dropdown .dropdown-menu .has-link a {
                font-size: 0.857rem !important;
            }

            .dropdown .dropdown-menu .has-link a {
              padding: 0.2rem 1rem;

              span.usermgmtlink-wide { display: none; }
              span.usermgmtlink-narrow { display: inline; }
            }

            // Vertically center setup dropdown menu chevron arrow
            .dropdown figcaption.is-flex .icon { height: 1rem; }
        }

        @include media-high-res-or-low-dpi {
            .dropdown .dropdown-menu .has-link a span.usermgmtlink-wide { display: inline; }
            .dropdown .dropdown-menu .has-link a span.usermgmtlink-narrow { display: none; }

            // Vertically center setup dropdown menu chevron arrow
            .dropdown figcaption.is-flex .icon { height: 1.4; }
        }

        @include media-huge-dpi {
            figure.overview-button figcaption { padding-top: 50px !important; }
            figure.manage-leads-button figcaption { padding-top: 50px !important; }
            figure.activities-button figcaption { padding-top: 50px !important; }
            figure.setup-button figcaption { padding-top: 50px !important; }
            figure.outlook-button figcaption { padding-top: 50px !important; }
            figure.account-button figcaption { padding-top: 50px !important; }

            .dropdown .dropdown-menu .has-link a {
              padding: 0.1rem 1rem;
            }
        }

        a {
            font-weight: 600;
        }

        @include media-low-res-or-big-dpi {
            a { font-size: 0.857rem; }
        }

        @include media-high-res-or-low-dpi {
            a { font-size: 0.929rem; }
        }
            

        figure figcaption {
            color: #959BA3;
            padding-bottom: 10px;
            text-transform: uppercase;
        }

        @include media-huge-dpi {
          figure figcaption { padding-bottom: 5px; }
        }

        a.is-active figure figcaption,
        a.is-active-dropdown figure figcaption {
            color: #EF6D6A;
        }

        a:hover figure.sidebar-button,
        a.is-active:hover figure.sidebar-button,
        .sidebar-bottom a:hover figure.sidebar-button,
        .sidebar-bottom a.is-active:hover figure.sidebar-button,
        .dropdown .dropdown-menu .has-link a:hover,
        .dropdown .dropdown-menu .has-link a:hover.is-active {
            background-color: #F6F6F6;
        }


        figure.overview-button {
            background: url('../../images/sidebar-overview.svg') center 10px no-repeat;
            background-size: 3rem;
        }

        a.is-active figure.overview-button {
            background: url('../../images/sidebar-overview-active.svg') center 10px no-repeat;
            background-size: 3rem;
        }


        figure.manage-leads-button {
            background: url('../../images/sidebar-manage-leads.svg') center 10px no-repeat;
            background-size: 3.35rem;
        }

        a.is-active figure.manage-leads-button {
            background: url('../../images/sidebar-manage-leads-active.svg') center 10px no-repeat;
            background-size: 3.35rem;
        }

        figure.activities-button {
            background: url('../../images/activities-icon.svg') center 10px no-repeat;
            background-size: 2.9rem;
        }

        a.is-active figure.activities-button {
            background: url('../../images/activities-icon-active.svg') center 10px no-repeat;
            background-size: 2.9rem;
        }

        @include media-low-res-or-big-dpi {
          figure.overview-button { margin-bottom: 0.6rem; }
          figure.manage-leads-button { margin-bottom: 0.6rem; }
          figure.activities-button { margin-bottom: 0.6rem; }
        } 

        @include media-high-res-or-low-dpi {
          figure.overview-button { margin-bottom: 1rem; }
          figure.manage-leads-button { margin-bottom: 1rem; }
          figure.activities-button { margin-bottom: 1rem; }
        }

        @include media-huge-dpi {
          figure.overview-button { margin-bottom: 0.2rem; }
          figure.manage-leads-button { margin-bottom: 0.2rem; }
          figure.activities-button { margin-bottom: 0.2rem; }
        }


        figure.setup-button {
            background: url('../../images/sidebar-setup.svg') center 10px no-repeat;
            background-size: 2.85rem;
        }

        a.is-active figure.setup-button,
        a.is-active-dropdown figure.setup-button {
            background: url('../../images/sidebar-setup-active.svg') center 10px no-repeat;
            background-size: 2.85rem;
        }

        figure.setup-button figcaption {
          padding-top: 60px;
        }


        .dropdown-menu {
          min-width: 100%;
          border-top: 1px solid #F8F8F8;
          padding-top: 0;
        }

        // Remove Setup dropdown menu shadow
        .dropdown-content { box-shadow: none; }

        @include media-huge-dpi {
          .dropdown-content { padding-top: 0.3rem; }
        }

        // Setup dropdown menu items
        .dropdown .dropdown-menu .has-link a {
          font-size: 1rem;
          font-weight: 500;
          color: #939BAE;
          text-transform: capitalize;
          white-space: normal !important;
        }

        .dropdown .dropdown-menu .has-link a.is-active {
          background-color: #fff;
          color: #EF6D6A;
        }

        div.sidebar-bottom {
          position: absolute;

          figure.outlook-button {
              background: url('../../images/sidebar-outlook.svg') center 10px no-repeat;
              background-size: 3.1rem;
          }

          a.is-active figure.outlook-button {
              background: url('../../images/sidebar-outlook-active.svg') center 10px no-repeat;
              background-size: 3.1rem;
          }

          figure.outlook-button figcaption {
            padding-top: 60px;
            padding-bottom: 5px;
          }

          figure.account-button {
              background: url('../../images/account-icon.svg') center 10px no-repeat;
              background-size: 3.1rem;
          }

          a.is-active figure.account-button {
              background: url('../../images/account-icon-active.svg') center 10px no-repeat;
              background-size: 3.1rem;
          }

          figure.account-button figcaption {
            padding-top: 60px;
          }

          @include media-low-res-or-big-dpi {
            div.outlook-switch {
              margin-top: 3px;
              margin-bottom: 10px;
            }
          }

          @include media-high-res-or-low-dpi {
            div.outlook-switch {
              margin-top: 3px;
              margin-bottom: 20px;
            }
          }

          @include media-huge-dpi {
            div.outlook-switch {
              margin-top: 3px;
              margin-bottom: 10px;
            }
          }

        }

        @include media-low-res-or-big-dpi {
          div.sidebar-bottom {
            width: 88px;
            bottom: 10px;
          }
        }

        @include media-high-res-or-low-dpi {
          div.sidebar-bottom {
            width: 122px;
            bottom: 30px;
          }
        }

        @include media-huge-dpi {
          div.sidebar-bottom {
            width: 88px;
            bottom: 10px;
          }
        }
    }

}


