@import "FRONTEND/css/_variables.scss";


.map-view-container {
  height: 100%;
  position: relative;

  .map-search {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
  }
}

