@import "FRONTEND/css/_variables.scss";


@include media-low-res-or-big-dpi {
  .quick-search-container { margin-top: 2px; }
}

@include media-high-res-or-low-dpi {
  .quick-search-container { margin-top: 5px; }
}

@include media-huge-dpi {
  .quick-search-container { margin-top: 0px; }
}


.quick-search-container {
  display: flex;
  flex-wrap: nowrap;

  .filter-input-area {
    width: 100%;

    &.with-filter-option {
      width: calc(100% - 23px);
    }
  }

  .filter-options-area {
    width: 23px;
    text-align: right;
    justify-self: center;
    align-self: center;
  }

  .filter-remove-icon {
    margin: 2px 0 0 2px;
    width: 23px;
    min-width: 23px;
    max-width: 23px;
    z-index: 1;

    .icon {
      i.active:hover { cursor: pointer; }
    }
  }

  .filter-remove-icon.active:hover { cursor: pointer; }

}

