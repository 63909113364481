@import "FRONTEND/css/_variables.scss";


.users-page {
  margin: 24px;

}

.rudolfbox.add-edit-user-container {
  position: relative;

  .user-info-form {
    height: 100%;

    .user-visibilities,
    .product-visibilities {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .item-container {
        margin-right: 10px;
        flex-grow: 1;
      }

      .access-level-select {
        width: 120px;
      }

      .remove-visibility {
        margin-left: 10px;

        &:hover { cursor: pointer; }
      }
    }

  }

}


.item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .item-img {
    line-height: 1;
    flex-basis: 40px;
    min-width: 40px;
  }

  .item-name {
    font-weight: 500;
    flex-grow: 1;
    line-height: 1;
  }
}

