@import "FRONTEND/css/_variables.scss";


nav { height: var(--topNavHeight); } // from styles.scss


nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s; 


  #topleft {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .hello-username {
      padding-left: 5px;
      padding-right: 20px;
      line-height: 1em;
      border-right: 1px solid #959BA3;

      article.media { align-items: center; }

      figure.media-left {
        display: flex;
        margin-right: 5px;
      }

      p.hello {
        font-size: 0.714rem;
        font-weight: 600;
        letter-spacing: 0px;
        color: #959BA3;
        text-transform: uppercase;
      }

      p.user {
        font-size: 0.857rem;
        font-weight: 600;
        letter-spacing: 0px;
        color: #959BA3;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 100px;

        .firstname {
          text-transform: uppercase;
        }
      }

    }

    .button-area {
      margin-left: 14px;

      .button.icon-button.is-light.is-large {
        padding: 20px 6px;
      }

      .button.is-light:hover { background-color: transparent; }

      .button.is-light:focus { box-shadow: none; }
    }

  }


  #topmiddle {
    h2.pagetitle {
      font-size: 1.714rem;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: uppercase;
      margin-right: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.3rem;
    }


    .menu-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;

      .icon {
        color: #959BA3;
      }
    }

    .menu-container:hover .icon {
      color: #3B3B3B;
    }


    .dropdown-menu .dropdown-item .dropdown-product-name,
    .dropdown-menu .has-link a {
      font-size: 1.143rem;
      font-weight: 600;
      color: #3B3B3B;
    }

    .dropdown-menu .dropdown-item.is-active .dropdown-product-name,
    .dropdown-menu .dropdown-item.is-active,
    .dropdown-menu .has-link a.is-active {
      background-color: #fff;
      color: #EF6D6A !important;
    }

    .dropdown-menu .dropdown-item.is-active:hover .dropdown-product-name,
    .dropdown-menu .dropdown-item:hover,
    .dropdown-menu .dropdown-item.is-active:hover,
    .dropdown-menu .has-link a:hover,
    .dropdown-menu .has-link a.is-active:hover {
      background-color: #f6f6f6;
      color: #3B3B3B;
    }

  }

  @media screen and (max-width: $tablet - 1) {
    #topmiddle {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: var(--topNavHeight);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 80px;

      h2.pagetitle {
        white-space: nowrap;
      }


      .menu-container {
        > .icon { padding-top: 4px; }
      }

      .submenu-container {
        text-align: center;
        width: 100%;

        h3.pagesubtitle {
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 500;
        }
      }


    }
  }



  #topright {
    padding-right: 10px;
    line-height: 1;
  }

  @media screen and (max-width: $tablet - 1) {
    #topright {
      padding-right: 5px;
    }
  }

}

