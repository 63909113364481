@import "FRONTEND/css/_variables.scss";


.login-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
}

.login-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}



//
// Responsive styling
//

@media screen and (min-width: $fullhd) {
  .login-form { width: 417px; }
  .rudolf-logo > img { margin-bottom: 50px; }
  .login-left {
    width: 60vw;
    background: url('../images/login-hero-1920.svg') center center no-repeat;
    background-size: cover;
  }
  .login-right { width: 40vw; }
}


@media screen and (min-width: $widescreen) and (max-width: $fullhd - 1) {
  .login-form { width: 340px; }
  .rudolf-logo { width: 220px; margin: 0 auto; }
  .rudolf-logo > img { margin-bottom: 50px; }
  .login-left {
    width: 52vw;
    background: url('../images/login-hero-1200.svg') center center no-repeat;
    background-size: cover;
  }
  .login-right { width: 48vw; }
}


// This one uses the 1200 hero svg
@media screen and (min-width: $desktop) and (max-width: $widescreen - 1) {
  .login-form { width: 340px; }
  .rudolf-logo { width: 220px; margin: 0 auto; }
  .rudolf-logo > img { margin-bottom: 40px; }
  .login-left {
    width: 60vw;
    background: url('../images/login-hero-1200.svg') center center no-repeat;
    background-size: cover;
  }
  .login-right { width: 40vw; }
  button.login-page-button.sign-in-button { margin-top: 50px !important; }
}


// tablet breakpoint, landscape mode
@media screen and (min-width: $tablet) and (max-width: $desktop - 1) and (max-height: 991px) {
  .login-form { width: 354px; }
  .rudolf-logo { width: 230px; margin: 0 auto; }
  .rudolf-logo > img { margin-bottom: 40px; }
  .login-left {
    display:none;
  }
  .login-right { width: 100vw; }
  button.login-page-button.sign-in-button { margin-top: 50px !important; }
}

// tablet breakpoint, portrait mode
@media screen and (min-width: $tablet) and (max-width: $desktop - 1) and (min-height: 992px) {
  .login-form { width: 354px; }
  .rudolf-logo { width: 230px; margin: 40px auto 0 auto; }
  .rudolf-logo > img { margin-bottom: 30px; }
  .login-left {
    position: static;
    width: 100vw;
    height: 50vh;
    background-color: #fff;
    display: block;
    background: url('../images/login-hero-768.svg') center center no-repeat;
    background-size: cover;
  }
  .login-right {
    position: static;
    width: 100vw;
    height: 50vh;
    align-items: flex-start;
  }
  button.login-page-button.sign-in-button { margin-top: 40px !important; }

  .create-new-password-paragraph { margin-bottom: 40px; }
  .new-password-again-field { margin-bottom: 50px !important; }
}

// everything below tablet (mobile)
@media screen and (max-width: $tablet - 1) {
  .login-form { width: 310px; }
  .rudolf-logo { width: 227px; margin: 0 auto; }
  .rudolf-logo > img { margin-bottom: 30px; }
  .login-left {
    display: none;
  }
  .login-right { width: 100vw; }
  button.login-page-button.sign-in-button { margin-top: 40px !important; }
}



.create-new-password-paragraph {
  text-align: center;
  margin-bottom: 60px;
}


.new-password-again-field {
  margin-bottom: 60px !important;
}




button.login-page-button {
    border-radius: 8px !important;
    height: 2.8em;
    font-size: 1rem !important;
    font-weight: 500 !important;
}


button.login-page-button.sign-in-button {
  margin-top: 70px;
  margin-bottom: 10px;
}


button.login-page-button.resend-code {
  background: #F2F2F2;
  box-shadow: none;
  color: #3b3b3b;
  border: 0;
}

.forgot-password {
  cursor: pointer;
  font-size: 1rem;
}




.login-form .sign-in-text {
  font-size: 1.286rem;
  font-weight: 500;
}

// Design has login page input field labels at 400 weight
.login-form .login-field-label {
  font-weight: 400 !important;
}

// Adjust "required" check mark
.control.has-icons-right .icon {
  height: 2.2em;
  color: #3b3b3b !important;
}


.pwd-reset-title {
  font-size: 1.571rem;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}



// Password reset verification code input
// https://codepen.io/RobertAron/pen/gOLLXLo
fieldset.number-code {
  // overflow: auto;
  > div {
    display: flex;
    > input:not(:last-child) {
      margin-right: 20px;
    }
  }

  margin-bottom: 15px;


  input.code-input {
    font-size: 1.286rem !important;
    width: 1em;
    text-align: center;
    flex: 1 0 1em;
    border-bottom: 3px solid #3b3b3b;
  }

  input.code-input.invalid {
    border-bottom: 3px solid hsl(348, 100%, 61%);
  }

  input{
    border: none;
    border-radius: 0 !important;

    &:invalid {
      box-shadow: none;
    }
    &:focus{
      outline: none;
      background: #EDF4F8;
    }
  }}

