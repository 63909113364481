@import "FRONTEND/css/_variables.scss";


.color-selection {
    width: 20px;
    height: 20px;
    margin: 10px 16px;
    border: 1px solid #959BA3;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.color-picker {
    width: 264px;
    background-color: #fff;

    padding: 12px;

    .colors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        gap: 24px;

        .color {
            width: 20px;
            height: 20px;
            border-radius: 4px;
        }
    }
}


