@import "FRONTEND/css/_variables.scss";


.monthly-chart-container {
  .monthly-chart-wrapper {
    margin-bottom: 10px;

    > div:first-child {
      position: relative;
      height: 100%;
    }
  }

  .chart-bottom-title-text {
    color: #000;
    font-weight: 400;
    text-align: center;
  }

}


