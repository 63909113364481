@import "FRONTEND/css/_variables.scss";


.manage-leads-mobile-container {
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  position: relative;

  .leads-table {
    height: 100%;
    position: relative;

    .leads-table-header {
      background-color: #f6f6f6;
      border-bottom: 2px solid #d9d9d9;
      padding: 12px;
      height: 72px;

      .leads-table-header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .leads-table-title {
          font-weight: 500;
          text-transform: uppercase;
          font-size: 1.143rem;
        }

      }

    }

    .leads-table-content {
      height: calc(100% - 72px - 50px);
      overflow-y: auto;

      @include rudolf-scrollbars;

      .lead-row {
        display: contents;

        > .main-lead-row,
        > .sub-lead-row {
          height: 50px;
          border-bottom: 1px solid #f6f6f6;
          display: flex;
          font-weight: 700;
          text-transform: uppercase;
          align-items: center;

          &:hover {
            background-color: #f6f6f6;
            cursor: pointer;
          }

          > .row-collapse {
            padding: 12px 9px 12px 12px;
            height: 49px;
          }

          > .row-content {
            padding: 0 12px;

            &.with-collapse { padding-left: 0; }
          }
        }

        > .sub-lead-row > .row-content {
          margin-left: 20px;
        }
      }
    }

    .leads-table-footer {
      position: absolute;
      bottom: 0;
      height: 50px;
      width: 100%;
      padding: 10px;
      background-color: #f6f6f6;
      border-top: 2px solid #d9d9d9;

      .rpagination {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        .pagination-per-page {
          .leads-per-page {
            display: flex;
            align-items: center;
          }
          
          .leads-per-page-dropdown {
            width: auto;
            margin-right: 0;
            z-index: 1600;
          }

          // Tighter b-dropdown menu here
          .button { padding: calc(.5em - 1px) calc(.75em - 1px); }
          .button.is-select {
            justify-content: flex-end;
            border-radius: 4px;
          }
          .button.is-select span:first-child { text-overflow: initial; }
        }

        .pagination-pages {
          height: 30px;

          font-weight: 500;
          text-transform: uppercase;

          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-wrap: nowrap;

          > div.pagination-button {
            display: flex;
            align-items: center;
            border-radius: 4px;
            width: 30px;
            height: 40px;
            justify-content: center;
          }

          > div.pagination-button:hover {
            background-color: #eaeaea;
            cursor: pointer;
          }

          > div.pagination-position {
            width: 70px;
            text-align: center;
            display: flex;
            align-items: center;
            line-height: 1rem;
            justify-content: center;
            column-gap: 4px;

            > #leadsPerPageInput {
              padding-top: 0.02em;
            }
          }
        }

      }
    }
  }
}

