@import "FRONTEND/css/_variables.scss";


@include media-low-res-or-big-dpi {
  .bulk-edit-container { height: calc(100vh - 226px); }
}

@include media-high-res-or-low-dpi {
  .bulk-edit-container { height: calc(100vh - 568px); }
}

.bulk-edit-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 .5em 1em -.125em hsla(0,0%,4%,.1),0 0 0 1px hsla(0,0%,4%,.02);
  height: calc(100% - 80px);
  position: absolute;
  right: 20px;
  top: 50px;
  width: 400px;
  z-index: 1500;

  .bulk-edit-top {
    height: 2.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
  }

  .bulk-edit-content {
    height: calc(100% - 100px);
    margin-top: 45px;
    overflow-y: auto;
    padding: 5px 10px;

    @include rudolf-scrollbars;
  }

  .bulk-edit-top,
  .bulk-edit-content {

  }

  .bulk-edit-bottom {
    height: 2.5rem;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }

}

