@import "FRONTEND/css/_variables.scss";


.personal-sales-chart-container {
  display: flex;
  align-items: center;

  .personal-sales-reach {
    flex-grow: 0;
    width: 170px;

    font-size: 0.857rem;
    color: $grey;
    font-weight: 500;

    text-align: center;

    padding: 10px;

    border-right: 1px solid #d9d9d9;

    margin-right: 10px;

    .reach-percentage {
      font-size: 1rem;
      color: $text;
    }
  }

  .personal-sales-chart-wrapper {
    flex-grow: 1;
    min-height: 0;
    padding-right: 10px;

    > div:first-child {
      position: relative;
      height: 100%;
    }
  }

}


