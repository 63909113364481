@import "FRONTEND/css/_variables.scss";


.manage-leads-sidebar {
  position: relative;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  .hide-show-sidebar {
    border-radius: 4px;
    border: 1px solid #C6C6C6;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
    display: inline-block;
    position: absolute;
    padding: 0 6px;
    height: 33px;
    top: 10px;
    left: 10px;

    &:hover {
      background-color: #eaeaea;
      cursor: pointer;
    }

    .icon { height: auto; line-height: 1.3; }
  }

  .sidebar-resize-handle {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: black;
    opacity: 0;
    width: 3px;
    cursor: e-resize;
    z-index: 5;
  }

  .sidebar-resize-handle:hover,
  /* The following selector is needed so the handle is visible during
  resize even if the mouse isn't over the handle anymore */
  .sidebar--being-resized .sidebar-resize-handle {
    opacity: 0.5;
  }

  .manage-leads-sidebar-content {
    margin-top: 60px;
    height: calc(100% - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    padding: 10px;
    position: relative;

    &.expanded { padding: 0 10px 10px; }

    @include rudolf-scrollbars;

    .map-collapse-trigger,
    .kpi-collapse-trigger {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }

    .map-container {
      margin-bottom: 12px;

      .toggle-map-maximize-container {
        position: absolute;

        bottom: 24px;
        right: 61px;

        width: 40px;
        height: 40px;

        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;

        background-color: #fff;
        border-radius: 2px;

        box-shadow: 1px 1px 3px 0px #adadad;
      }

    }

    .kpi-container {
      .sales-values {
        display: grid;
        grid-template-columns: fit-content(100%) 1fr;

        .textcut {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .separator {
      height: 2px;
      background-color: #C6C6C6;
      margin-bottom: 12px;
    }


    .sidebar-rgrid-header {
      height: calc(1.714rem + 5px)
    }


    .sidebar-rgrid-scroller {
      height: calc(100% - 1.714rem - 5px - 40px);
      overflow-x: hidden;
      overflow-y: auto;

      @include rudolf-scrollbars;

      .sidebar-rgrid {
        display: grid;
        grid-template-columns: 40px 1fr;

        .sidebar-rgrid-header {
          grid-column: 1 / 3;
          border-bottom: 2px solid #d9d9d9;
          position: sticky;
          top: 0;
          z-index: 2;
          background-color: #f6f6f6;
          color: #959ba3;
          font-weight: 600;
          height: 100%;
          padding: 7px;

          &.row-collapse { left: 0px; }

          &.lead-name-header { left: 40px; }

          .column-header {
            display: flex;
            flex-wrap: nowrap;

            .column-name {
              white-space: nowrap;
              flex-grow: 1;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }

            .column-sort {
              height: 21px;

              &:hover { cursor: pointer; }
            }
          }

        }

        .sidebar-rgrid-row {
          display: contents;

          .sidebar-rgrid-main-row,
          .sidebar-rgrid-subrow {
            display: contents;

            &:hover .sidebar-rgrid-entry {
              background-color: #f6f6f6;
              cursor: pointer;
            }
          }

          .sidebar-rgrid-entry {
            align-self: stretch;
            background-color: #fff;
            overflow: hidden;
            padding: 7px;

            &.row-collapse,
            &.lead-name {
              position: sticky;
              z-index: 1;

              .lead-number {
                background-color: #f5f5f5;
                border-radius: 4px;
                padding: 1px 4px;
                color: #959ba3;
                font-weight: 500;
                font-size: 12px;
              }
            }

            &.row-collapse {
              left: 0;

              .sidebar-row-collapse-button {
                height: 100%;
                width: 100%;
              }
            }

            &.lead-name { left: 40px; }
          }
        }
      }
    }

    .sidebar-rgrid-footer {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      height: 30px;

      .pagination-per-page {
        flex-grow: 1;

        .leads-per-page {
          display: flex;
          align-items: center;
        }
        
        .leads-per-page-dropdown {
          width: auto;
          margin-right: 14px;
        }

        // Tighter b-dropdown menu here
        .button { padding: calc(.5em - 1px) calc(.75em - 1px); }
        .button.is-select {
          justify-content: flex-end;
          border-radius: 4px;
        }
        .button.is-select span:first-child { text-overflow: initial; }
      }

      .pagination-pages {
        height: 30px;

        flex-grow: 1;

        font-weight: 500;
        text-transform: uppercase;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;

        > div.pagination-button {
          display: flex;
          align-items: center;
          border-radius: 4px;
          width: 30px;
          height: 30px;
          justify-content: center;
        }

        > div.pagination-button:hover {
          background-color: #eaeaea;
          cursor: pointer;
        }

        > div.pagination-position {
          width: 85px;
          text-align: center;
          line-height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 4px;
        }
      }

    }
  }
}


