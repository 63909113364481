@import "FRONTEND/css/_variables.scss";


.rudolf-business-card-scanner-container {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  left: 0;
  bottom: calc(-100dvh);
  z-index: 8000;
  background: #fff;
  list-style: none;

  transition: bottom 0.1s linear;

  &.show {
    bottom: 0;
  }

  .rudolf-business-card-scanner-close {
    cursor: pointer;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    z-index: 8001;
    background-color: rgba(255, 255, 255, .6);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .business-card-scanner-camera-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    #ai-scan-pause-frame {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
    }

    #scanner-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .camera-status-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
      }

      .ai-scan-button {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        z-index: 8001;
        text-align: center;
        padding: 0 7px;
      }


      .helper-text {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        z-index: 8001;
        color: #000;
        text-align: center;
        background-color: rgba(255, 255, 255, .6);
        padding: 0 7px;
      }

    }

  }
}


