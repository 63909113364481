@import "FRONTEND/css/_variables.scss";


.mobile-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;

  .nav-button {
    width: 60px;
    height: 65px;

    &.qr-scanner-button {
      background: url('../../images/qr-code-scan-icon.svg') center center no-repeat;
      background-size: 2.6rem;
    }

    &.activities-button {
      background: url('../../images/activities-icon.svg') center center no-repeat;
      background-size: 2.7rem;

      &.active { background-image: url('../../images/activities-icon-active.svg'); }
    }

    &.add-lead-contact-button {
      background: url('../../images/plus-icon.svg') center center no-repeat;
      background-size: 2.4rem;

      &.active { background-image: url('../../images/plus-icon-active.svg'); }
    }

    &.map-view-button {
      background: url('../../images/map-view-icon.svg') center center no-repeat;
      background-size: 2.7rem;

      &.active { background-image: url('../../images/map-view-icon-active.svg'); }
    }

    &.manage-leads-button {
      background: url('../../images/mobile-bottom-manage-leads.svg') center center no-repeat;
      background-size: 3rem;

      &.active { background-image: url('../../images/mobile-bottom-manage-leads-active.svg'); }
    }

    &:hover {
      background-color: #F6F6F6;
    }
  }

}


