@import "FRONTEND/css/_variables.scss";


.page-content-inner {
  // Remove right margin as our sidebar starts from the side of the screen
  margin-right: 0;
  width: calc(var(--innerWidth) + var(--innerMargin));
}


@media screen and (max-width: $tablet - 1) {
    .page-content-inner {
      margin: var(--innerMargin) 0 0 0;
      --innerWidth: calc(100vw);
      --innerHeight: calc(100% - var(--innerMargin));
      width: calc(var(--innerWidth));

      .manage-leads-container {
        grid-template-columns: 1fr !important;
      }
    }
}


.manage-leads-container {
  display: grid;
  grid-template-rows: 100%;
  column-gap: 20px;
  height: 100%;

  &:not(.being--resized) {
    // Sidebar show/hide animation
    transition: all ease-in-out 0.25s;
  }

  .toggle-map-maximize-container {
    position: absolute;

    bottom: 24px;
    right: 61px;

    width: 40px;
    height: 40px;

    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;

    background-color: #fff;
    border-radius: 2px;

    box-shadow: 1px 1px 3px 0px #adadad;
  }
}

