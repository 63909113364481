@import "FRONTEND/css/_variables.scss";


.add-edit-lead {
  padding: 12px;


  .lead-details-columns {
    display: flex;
    justify-content: space-between;
    column-gap: 1.143rem;

    .lead-details-grid {
      column-gap: 1.143rem;
      display: grid;
      font-size: .857rem;
      grid-auto-rows: fit-content(100%);
      grid-template-columns: fit-content(200px) minmax(auto, 250px) fit-content(200px) minmax(auto, 250px);
      line-height: 1rem;
      row-gap: 1rem;
      flex-grow: 1;

      .existing-lead-selected {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        filter: drop-shadow(0 3px 6px #c7c7c7);
        font-size: .929rem;
        padding: 10px;
        font-weight: 600;

        > .lead-name { flex-grow: 1; }
        > .cancel { padding: 0 5px; }
      }

      &.edit {
        grid-template-columns: repeat(2, minmax(0, 400px));
      }

      .lead-name {
        grid-column: 1 / 5;
        font-weight: 600;
        font-size: 1rem;
      }

      .main-details-column {
        grid-column: 1 / 3;
      }

      .additional-details-column {
        grid-column: 3 / 5;
      }

      .leadfield {
        font-weight: 500;

        &.linkedin {
          display: flex;

          .icon.has-text-light {
            height: 1rem;
            width: 1.1rem;
            color: $grey !important;
            font-size: 13px !important;
          }
        }
      }

      .leadvalue {
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 1.143rem;
      }

      .lead-freetext-comment {
        grid-column: 1 / 5;

        .lead-freetext-comment-area {
          white-space: break-spaces;
          background-color: #f5f5f5;
          padding: 10px;
        }
      }

      .lead-tags {
        grid-column: 1 / 5;

        .tags > .tag {
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .editfield {
        &.hq-checkbox {
          align-self: end;
          font-size: 1rem;
          padding-bottom: 5px;
          text-transform: uppercase;
          color: $text;
          margin-left: 8px;
        }

        &.comments, &.tags { grid-column: 1 / 3; }
      }

    }

    @media screen and (max-width: $tablet - 1) {
      .lead-details-grid {
        grid-template-columns: fit-content(100%) 1fr;

        .lead-name, .lead-freetext-comment, .lead-tags { grid-column: 1 / 3; }

        &.edit {
          grid-template-columns: 1fr;
        }

        .editfield {
          &.comments, &.tags { grid-column: auto; }
        }

      }
    }

    .lead-location-preview,
    .lead-location-wrapper {
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      width: 400px;
      height: 300px;

      .lead-preview-map,
      .lead-location-map {
        height: calc(100% - 55px);

        #lead-preview-map-component,
        #lead-location-map-component {
          height: 100%;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
      }

      .lead-map-address-container {
        display: flex;
        height: 55px;
        align-items: center;
        margin: 0 10px;

        .lead-map-address {
          color: #959ba3;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

    }

  }


  .document-link-cards {
    display: flex;

    flex-wrap: wrap;

    column-gap: 20px;
    row-gap: 10px;

    .new-document-link-card {
      background: #fff;
      border-radius: 5px;
      display: flex;
      width: 21.429rem;
      border: 3px solid #eaeaea;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;

        .icon { color: #3b3b3b; }
      }

      .icon {
        color: #eaeaea;
        width: auto;
        height: auto;
        line-height: initial;
        margin: 5px 0;
      }
    }

    .document-link-card {
      border-radius: 5px;
      background: #F5F5F5;
      width: 21.429rem;
      padding: 10px;

      display: flex;

      .document-link-card-icon {
        width: 2.857rem;
      }

      .document-link-card-content {
        width: calc(21.429rem - 2.857rem - 1.429rem - 30px);
        padding-left: 10px;

        .document-link-card-text {
          color: #000;
          font-size: .857rem;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .document-link-card-url {
          color: $grey;
          font-size: .857rem;
          font-weight: 500;

          width: 100%;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.new {
          width: calc(21.429rem - 2.857rem - 25px);
        }

        &.edit {
          white-space: initial;
          overflow: initial;
          text-overflow: initial;

          .edit-document-link-card-inputs {
            margin-bottom: 8px;
          }

          .buttons > .button.is-light.is-small:hover { background-color: #fff; }
        }
      }

      .document-link-card-buttons {
        width: 1.429rem;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
    }

  }


  .contacts-header {
    display: flex;
    align-items: center;

    margin-bottom: 0.75rem;
  }


  .contact-cards {
    display: flex;

    flex-wrap: wrap;

    column-gap: 20px;
    row-gap: 10px;

    .new-contact-card {
      background: #fff;
      border-radius: 5px;
      display: flex;
      padding: 12px 16px;
      width: 28.571rem;
      border: 3px solid #eaeaea;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;

        .icon { color: #3b3b3b; }
      }

      .icon {
        color: #eaeaea;
        width: auto;
        height: auto;
        line-height: initial;
        margin: 5px 0;
      }
    }

    .contact-card {
      border-radius: 5px;
      background: #F5F5F5;
      width: 28.571rem;
      padding: 12px 16px;

      display: flex;

      &.primary-contact {
        border: 2px solid #3b3b3b;
        padding: 10px 14px;
      }

      .contact-card-icon {
        margin-right: 20px;
      }

      .contact-card-content {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .contact-card-name {
          color: #000;
          font-size: 0.857rem;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .contact-card-position-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.857rem;
          font-weight: 500;
          color: #000;
        }


        &.edit {
          white-space: initial;
          overflow: initial;
          text-overflow: initial;

          .edit-contact-inputs {
            margin-bottom: 8px;
          }

          .buttons > .button.is-light:hover { background-color: #fff; }
        }
      }

      .contact-card-buttons {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: $tablet - 1) {
    .contact-cards {
      flex-direction: column;
      row-gap: 1rem;
      flex-wrap: nowrap;

      .contact-card, .new-contact-card {
        width: 100%;

        .contact-card-content {

          .contact-card-name,
          .contact-card-position-title {
            font-size: 1rem;
          }
        }
      }
    }
  }

}

@media screen and (max-width: $tablet - 1) {
  .add-edit-lead {
    margin: 0;
    padding: 0;
  }
}


