@import "FRONTEND/css/_variables.scss";


.account-page-columns {
  height: calc(100%);


  .account-page-box {
    height: 100%;
    padding: 12px;

    textarea { resize: none !important; }
  }


  .user-info-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    color: #959BA3;
    font-weight: 500;

    .user-title {
        font-size: 1.286rem;
        font-weight: 500;
        color: #3b3b3b;
    }

    .user-organization {
        font-weight: 500;
    }

    .user-role {
        font-size: 0.857rem;
        font-weight: 600;
        text-transform: capitalize;
    }
  }

  
  .edit-my-information-box {
    .help {
      position: absolute;
    }
  }


  .roles-assigned-box {

    padding-right: 0px;

    .roles-scroll-area {
      max-height: 350px;
      overflow-y: auto;


      @include rudolf-scrollbars;
    }

    .role-title {
      font-weight: 600;
      font-size: 0.857rem;
      margin-bottom: 12px;
    }

    .user-item {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 12px;
      margin-right: 12px;

      .user-img {
        line-height: 1;
        flex-basis: 40px;
        min-width: 40px;
      }

      .user-name {
        font-weight: 500;
        flex-grow: 1;
        line-height: 1;
      }
    }
  }


  .change-password-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .help {
      margin-left: 10px;
      color: #959BA3;
      font-size: 0.857rem;
      letter-spacing: -0.01rem;

      &.is-danger { color: #f14668; }
    }

  }


  .send-feedback-box {
    .help {
      position: absolute;
    }
  }

}


