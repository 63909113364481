@import "FRONTEND/css/_variables.scss";


.rnotifications-button {
  position: relative;
  padding: 7px;

  .rnotifications-counter {
    position: absolute;
    top: -4px;
    right: -4px;

    width: 23px;
    height: 23px;

    padding: 5px 0;

    background: #e50b07;
    border-radius: 50%;
    color: #fff;
    text-align: center;

    font-weight: 600;
    font-size: 0.929rem;
  }

  &:hover {
    cursor: pointer;
  }
}


.rnotifications-container {
  height: 500px;
  margin-bottom: 10px;
  max-height: calc(100vh - 70px);
  padding: 7px 0 34px;
  position: relative;
  width: 365px;
  max-width: calc(100vw);
  z-index: 1700;

  @media screen and (max-width: $tablet - 1) {
    width: calc(100vw - 10px);
  }

  .rnotifications-close {
    right: 0;
    position: absolute;
    top: -5px;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }

  .rnotifications-top {
    display: flex;

    .rnotifications-title {
      flex-grow: 1;
      margin-left: 7px;
    }

    .rnotifications-top-buttons {
      margin-right: 20px;
      display: flex;
    }
  }

  .rnotifications-list {
    height: calc(100% - 2.143rem);
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid #eaeaea;

    .rnotification {
      display: flex;
      background-color: #f6f6f6;
      padding: 10px;
      border-bottom: 1px solid #959BA3;

      z-index: 1;

      cursor: pointer;

      &.rnotification-list-move,
      &.rnotification-list-enter-active,
      &.rnotification-list-leave-active {
        transition: all 0.5s ease;
      }
      &.rnotification-list-enter-from,
      &.rnotification-list-leave-to {
        opacity: 0;
        transform: translateX(30px);
      }
      &.rnotification-list-leave-active {
        position: absolute;
      }

      &.read:hover,
      &:hover { background-color: #eaeaea; }

      &:last-of-type { border-bottom: none; }

      .rnotification-text {
        flex-grow: 1;
        font-weight: 500;

        .rnotification-time {
          font-size: 0.857rem;
          color: $grey;
          font-weight: 500;

          sup {
            font-size: .7em;
            position: relative;
            top: 1.5px;
            vertical-align: top;
          }
        }
      }

      &.read {
        background-color: #fff;

        .rnotification-text { font-weight: 400; }
      }

      .rnotification-buttons {
        display: flex;

        z-index: 5;

        .rnotification-mark-as-read {
        }

        .rnotification-delete {

        }
      }

    }

    @include rudolf-scrollbars;
  }


  .rnotifications-footer {
    bottom: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 1.1rem;
  }
}


