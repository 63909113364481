@import "FRONTEND/css/_variables.scss";


.bulk-manage {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 .5em 1em -.125em hsla(0,0%,4%,.1),0 0 0 1px hsla(0,0%,4%,.02);
  height: calc(100% - 80px);
  position: absolute;
  right: 20px;
  top: 50px;
  width: 400px;
  z-index: 1500;

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 4px;
    padding: 2px 2px 0 2px;

    &:hover {
      background-color: #eaeaea;
      cursor: pointer;
    }
  }

  .bulk-manage-tabs {
    height: calc(100% - 10px - 2.5rem - 10px);
    padding: 15px 10px 0 10px;

    .bulk-products-top {
      margin-bottom: 15px;
    }

    .bulk-products-top,
    .bulk-products-content {
      .products-columns {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .product-value-column,
        .products-column {
          flex-basis: 50%;
          flex-grow: 1;
          width: 50%;
        }

        .product-value-column {
          .status-selection {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 150px;

            .status-color {
              width: 20px;
              height: 20px;
              min-width: 20px;
              margin-right: 12px;
              border: 1px solid #959BA3;
              border-radius: 4px;
              position: relative;
            }

            .status-text {
              white-space: nowrap;
              flex-grow: 1;
              overflow-x: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .b-checkbox.checkbox {
          width: 100%;
        }
      }
    }
  }



  .bulk-manage-bottom {
    height: 2.5rem;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    .bulk-delete-leads-button {
      cursor: pointer;
    }
  }

}

