@import "FRONTEND/css/_variables.scss";


div.item-list {

    position: relative;

    margin-bottom: 24px;

    div.item:first-child {
        border: 1px solid #F6F6F6;
        border-radius: 8px 8px 0 0;
        border-bottom: 0;
    }

    div.item:nth-last-child(1) {
        border: 1px solid #F6F6F6;
        border-radius: 0 0 8px 8px;
        border-top: 0;
    }

    div.item:only-child {
        border: 1px solid #F6F6F6;
        border-radius: 8px;
    }

    div.item:nth-child(odd) {
      background-color: #fafafa;
    }

    div.item:hover {
        background-color: #f6f6f6;
    }

    div.item.sortable-drag {
        background-color: #EF6D6A !important;
    }

    div.item.sortable-ghost {
      background-color: #EF6D6A !important;
      opacity: 0.5;
    }


    div.item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-start;

        row-gap: 10px;

        padding: 12px 12px 12px 16px;

        border-left: 1px solid #F6F6F6;
        border-right: 1px solid #F6F6F6;

        cursor: pointer;

        font-size: 1rem;
        font-weight: 600;
        color: #3B3B3B;
        text-transform: uppercase;


        .color-selection {
            width: 20px;
            height: 20px;
            min-width: 20px;
            margin-right: 12px;
            border: 1px solid #959BA3;
            border-radius: 4px;
            position: relative;
            cursor: pointer;
        }


        div.item-name {
            line-height: 14px;
        }

    }
}

