@import "FRONTEND/css/_variables.scss";


.localization-edit {
  height: 100%;

  .localization-edit-top {
    height: 50px;

    .localization-edit-top-inner {
      display: flex;
      align-items: flex-end;
      column-gap: 15px;
    }
  }

  .localization-edit-content {
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;

    @include rudolf-scrollbars;

    .localization-category {
      .category-header {
        background-color: #eaeaea;
        padding: 10px;
        border-radius: 8px;

        &.has-missing-translations {
          background-color: #FF0000;
          color: #fff;
        }
      }

      .localization-fields {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-left: 20px;

        .localization-field {
          width: 250px;
          padding: 8px;

          &.is-default-translation {
            border: 3px solid red;
            padding: 5px;
          }
        }

      }
    }
  }

  .localization-edit-bottom {
    height: 50px;
    padding-top: 10px;
    margin-right: 30px;
  }
}

