@import "FRONTEND/css/_variables.scss";


.leftcolumn {
    margin: 12px 0 0 12px;
    
    //padding: 12px;

    h4.product-list,
    h4.selected-product {
        margin-bottom: 14px;
    }


    .searchfield .is-small {
        font-size: 0.85rem !important;
    }


    input.product-name-input,
    input.product-search-input {
        letter-spacing: 0.56px;
        font-weight: 400 !important;
    }

    input.product-name-input { 
      min-width: 100px;
    }


    .sorting-note {
      margin: 12px 40px 0 40px;
      color: #959ba3;
      font-size: 0.857rem;
      text-align: center;
      font-style: italic;
    }



    div.separator {
        height: 1px;
        border-bottom: 1px solid #F6F6F6;
        margin: 12px -12px 12px -12px;
    }


    .search-result {
        text-transform: uppercase;
        color: #959BA3;
        margin-top: 24px;
        text-align: center;
    }


    div.product-list {

        position: relative;

        div.product:first-child {
            border: 1px solid #F6F6F6;
            border-radius: 8px 8px 0 0;
            border-bottom: 0;
        }

        div.product:nth-last-child(1) {
            border: 1px solid #F6F6F6;
            border-radius: 0 0 8px 8px;
            border-top: 0;
        }

        div.product:only-child {
            border: 1px solid #F6F6F6;
            border-radius: 8px;
        }

        div.product:hover {
            background-color: #f6f6f6;
        }

        div.product.active{
            background-color: #eaeaea;
        }

        div.product.sortable-drag {
            background-color: #EF6D6A !important;
        }

        div.product.sortable-ghost {
          background-color: #EF6D6A !important;
          opacity: 0.5;
        }


        div.product {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            row-gap: 10px;

            padding: 12px 20px;

            border-left: 1px solid #F6F6F6;
            border-right: 1px solid #F6F6F6;

            cursor: pointer;

            font-size: 1rem;
            font-weight: 600;
            color: #3B3B3B;
            text-transform: uppercase;


            div.product-name {
                margin: auto 0;
            }

            div.edit-product {
                width: 20px;
                margin-left: auto;
                margin-right: 10px;
            }

            div.delete-product {
                width: 20px;
            }

            div.edit-product,
            div.delete-product {
              .has-text-light { color: #eee; }
            }


            div.save-product-name {
              margin-left: auto;
              margin-right: 10px;
            }

            .cancel-save-product-name button:hover {
              background-color: #fff !important;
            }

        }
    }
}


.rightcolumn {
  overflow-y: auto;
  margin: 12px 0 0 0 ;
  //padding: 24px 24px 0 24px;
  height: calc(100%);
  padding: 0 0 24px 0;

  @include rudolf-scrollbars;
}


