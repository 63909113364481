@import "FRONTEND/css/_variables.scss";


.data-page {
    margin: 0 auto;

    max-width: 1446px;

    .data-button {
      height: 1em !important;
      padding: 2px 12px 12px 12px !important;
    }


    .data-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid #CDD1DA;

        padding: 12px 0;

        margin: 24px 0;

        cursor: pointer;
    }

    .data-content.last {
        border-bottom: 0;
        cursor: default;


        .file-cta {
          background-color: #fff !important;
          color: #3b3b3b !important;
          border-radius: 8px;
          padding: 1.8em 1.3em;

          .file-icon {
            margin-right: 0;
          }
        }

        .file-cta:hover {
          background-color: #f6f6f6 !important;
        }

        .tag {
          font-size: 1rem;
          font-weight: 500;
        }
    }


    .upload-assignments-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 465px;

      .upload-assignments-title {
        font-weight: 600;
      }
    }

    .upload-assignments-product-list {
        height: 250px;
        overflow-y: scroll;
        margin: 0 16px 20px 0;
        padding-right: 10px;

        @include rudolf-scrollbars;
    }

    .upload-assignments-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 465px;

      .upload-assignments-products-assignee-title {
        font-weight: 500;
        color: #959BA3;
      }

      .upload-assignments-assignee-column,
      .upload-assignments-products-column {
        flex-basis: 50%;
        max-width: calc(465px / 2);
      }
    }

}


.bulk-action-modal {
  display: flex;
  align-items: center;

  .results-container {
    text-align: left;

    font-size: 0.857rem;

    .results-row {
      display: flex;
      line-height: 1.2em;

      .results-row-number {
        text-align: right;
        width: 40px;
        min-width: 40px;
        padding-right: 5px;
      }

      .results-row-content {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .results-row-subcontent {
          font-style: italic;
          margin-left: 15px;
        }
      }
    }
  }

}

