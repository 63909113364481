@import "FRONTEND/css/_variables.scss";


.kpi-data-upload {
  display: flex;
  align-items: center;
  height: 45px;

  .upload-for {
    margin-right: 30px;
  }


  .attach-file {
    margin-right: 30px;
  }

  .attached-file {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 5px 10px;

    .attachment-icon { margin-right: 5px; }
    .attachment-file { margin-right: 5px; }

    .remove-attachment {
      padding: 5px;

      &:hover { cursor: pointer; }
    }
  }

  .upload-for-dropdown { width: 200px; }

}


/**
 * Equal width flexbox columns grid
 *
 * Custom styling for the KPI weights table, so months flow nicely in a responsive layout
 *
 * Adapted from https://medium.com/developedbyjohn/equal-width-flex-items-a5ba1bfacb77
 */

.kpi-weight-months {
  display: flex;
  flex-wrap: wrap;
  max-width: 2000px;
}

.month-container {
  padding: 10px 10px;
  border: 1px solid transparent;
  text-align: right;
  margin: 5px;
  width: 250px;
  flex-basis: 250px;
  flex-grow: 1;
}

.month-placeholder {
  width: 250px;
  height: 2px;
  margin: 5px;
  background-color: transparent;
  
  flex-basis: 250px;
  flex-grow: 1;
}


