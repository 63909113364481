@import "FRONTEND/css/_variables.scss";


.labels-page {
  height: calc(100% - 48px);
  margin: 24px;
  padding-right: 10px;

  overflow-y: auto;
  overflow-x: hidden;

  @include rudolf-scrollbars;
}


