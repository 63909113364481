@import "FRONTEND/css/_variables.scss";


.statuses-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    row-gap: 24px;

    align-items: center;

    > .statuses-title {
        flex-grow: 1;
    }
}

.new-status-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .status-text.new {
        color: #959BA3 !important;
    }
}

.status-text {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    width: 85px;
    min-width: 85px;
}


.field:not(:last-child) {
    margin-bottom: 0 !important;
}


.statuses {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    margin-top: 30px;
    justify-content: flex-start;
}

.status {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    flex: 33.33%;
    max-width: 33.33%;
    min-width: 390px;
}


.delete-status {
    cursor: pointer;
}


