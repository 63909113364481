@import "FRONTEND/css/_variables.scss";


.task-pipeline-container {
  height: calc(100% - 1.7rem);
  width: 100%;

  .task-pipeline-scroll-left-container,
  .task-pipeline-scroll-right-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    top: 0;

    color: $grey;

    -webkit-transition: color 0.3s ease, transform 0.3s ease;
    -moz-transition: color 0.3s ease, transform 0.3s ease;
    -ms-transition: color 0.3s ease, transform 0.3s ease;
    -o-transition: color 0.3s ease, transform 0.3s ease;
    transition: color 0.3s ease, transform 0.3s ease;

    &:hover {
      color: #000;
    }
  }

  .task-pipeline-scroll-left-container {
    left: 0;

    &:hover {
      transform: translate3d(5px, 0, 0);
    }
  }

  .task-pipeline-scroll-right-container {
    right: 0;

    &:hover {
      transform: translate3d(-5px, 0, 0);
    }
  }


  .task-pipeline-scroll-container {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    overflow-x: hidden;

    //justify-content: space-evenly;

    height: 100%;

    margin: 0 40px;

    padding-top: 2px; // otherwise box-shadow clips on top

    cursor: pointer;

    width: calc(100% - 80px); 

    &.left-fade {
      -webkit-mask-image: linear-gradient(to left, black 90%, transparent 100%);
      mask-image: linear-gradient(to right, left 90%, transparent 100%);
    }

    &.both-fade {
      -webkit-mask-image:
        linear-gradient(to left, black 90%, transparent 100%),
        linear-gradient(to right, black 90%, transparent 100%);
      -webkit-mask-size: 100%;
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-composite: source-in;

      mask-image:
        linear-gradient(to left, black 90%, transparent 100%),
        linear-gradient(to right, black 90%, transparent 100%);
      mask-size:  100%;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-composite: source-in;
    }

    &.right-fade {
      -webkit-mask-image: linear-gradient(to right, black 90%, transparent 100%);
      mask-image: linear-gradient(to right, black 90%, transparent 100%);
    }


    &.dragging {
      cursor: grabbing;
    }

    .task-pipeline-item:first-child { margin-left: 11px; }
    .task-pipeline-item:last-child { margin-right: 11px; }

    .task-pipeline-item {
      flex: 0 0 130px;
      width: auto;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;
      width: 130px;

      &.selected { background-color: #eaeaea; }
      &.selected:hover { background-color: #eaeaea; }

      &:hover { background-color: #f6f6f6; }

      .item-inner {
        margin: 5px 15px 0 15px;
        height: 100%;

        .task-pipeline-status-text {
          color: $grey;
          font-weight: 500;
          font-size: 0.857rem;
          text-transform: uppercase;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }
  }

}

