@import "FRONTEND/css/_variables.scss";


.advanced-filter-button {
  position: relative;
  padding: 7px;

  &.mobile {
    padding: 0;
    height: 25px;
  }

  .num-filters-active {
    position: absolute;
    top: -4px;
    right: -4px;

    width: 23px;
    height: 23px;

    padding: 5px 4px;

    background: #e50b07;
    border-radius: 50%;
    color: #fff;
    text-align: center;

    font-weight: 600;
    font-size: 0.929rem;
  }

  @media screen and (max-width: $tablet - 1) {
    .num-filters-active {
      font-size: .8rem;
      height: 20px;
      padding: 2px 4px;
      right: -8px;
      top: -12px;
      width: 20px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.advanced-filter-container {
  width: 365px;
  padding: 10px 0 50px 0;
  margin-bottom: 10px;
  position: relative;

  &.mobile { width: 100%; }

  .mediumtext { font-size: 0.929rem; }

  .filter-options-area {
    display: flex;
    justify-content: space-between;

    margin: 5px 5px 0 5px;

    .switch input[type=checkbox]:checked+.check:before {
      transform: translate3d(80%,0,0);
    }

    span.reset {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .filters-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F6F6F6;
    padding-bottom: 5px;

    .category-name {
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
    }

    .category-arrow {
      color: $grey;
    }
  }

  .filter-details {
    .status-container {
      display: flex;

      .status-color {
        height: 32px;
        width: 4px;
        min-width: 4px;
        min-height: 32px;
        border-radius: 2px;
        margin-right: 2px;
      }

      .status-product {
        font-size: 0.857rem;
        font-weight: 500;
        line-height: 1.2em;
        width: 100%;

        .status-product-name,
        .status-status {
          display: inline-block;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
    }
  }

  .more-filters {
    margin-left: 10px;
    margin-top: 30px;
  }


  .filter-answers {
    .question {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;

        width: 100%;

        margin-bottom: 30px;

        .question-container {
            border: 1px solid #D9D9D9;
            border-radius: 8px;
            font-weight: 500;

            padding: 16px 14px 14px 14px;

            width: 100%;

        }

        .question-filter-options-area {
          display: flex;
          justify-content: space-between;

          margin: 5px 5px 0 5px;

          .question-type-text {
            font-weight: 500;
            font-size: 0.786rem;
            color: #959BA3;
            padding-top: 2px;
            padding-right: 5px;
            text-align: right;
            margin: 0;
            line-height: 1;
          }

          .switch input[type=checkbox]:checked+.check:before {
            transform: translate3d(80%,0,0);
          }
        }
    }
  }


  .bottom-buttons-area {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    margin: 0 20px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}

