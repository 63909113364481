@import "FRONTEND/css/_variables.scss";


.table-settings {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 .5em 1em -.125em hsla(0,0%,4%,.1),0 0 0 1px hsla(0,0%,4%,.02);
  height: calc(100% - 80px);
  position: absolute;
  right: 20px;
  top: 50px;
  width: 400px;
  z-index: 1500;

  .table-settings-top {
    display: flex;
    height: 2.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
  }

  .table-settings-bottom {
    height: 2.5rem;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }

  .table-settings-middle {
    height: calc(100% - 100px);
    margin-top: 45px;

    .select-all-clear-all {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      margin-bottom: 11px;
      border-bottom: 1px solid #EAEAEA;

      .select-all { margin-left: 10px; }
    }

    .column-selector-sections {
      height: 100%;

      .column-select-lead-questions-products {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        flex-wrap: wrap;
        padding-bottom: 10px;
        border-bottom: 1px solid #f6f6f6;

        .lead-product-with-questions {
          flex-grow: 1;
          flex-basis: 50%;
          white-space: nowrap;
          overflow: hidden;

          .field .control-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 150px;
          }
        }
      }

      .column-select-category-collapse {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F6F6F6;
        padding-bottom: 5px;
        margin-bottom: 14px;

        .category-name {
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .category-arrow {
          color: $grey;
        }
      }
    }


  }
}

