@import "FRONTEND/css/_variables.scss";


.task-pipeline-doughnut-wrapper {
    height: calc(100% - 35px);
    margin-bottom: 5px;

  > div:first-child {
    position: relative;
    height: 100%;
  }
}


