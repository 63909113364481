@import "FRONTEND/css/_variables.scss";


.add-lead-contact-mobile-container {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  left: 0;
  bottom: calc(-100dvh);
  z-index: 8000;
  background: #fff;
  list-style: none;

  transition: bottom 0.1s linear;

  &.show {
    bottom: 0;
  }

  .top-area {
    position: absolute;
    width: 100%;

    top: 0;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 2px solid #f6f6f6;

    h1 {
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .content-area {
    position: absolute;
    width: 100%;

    top: 50px;
    bottom: 150px;

    overflow-x: hidden;
    overflow-y: auto;

    padding: 20px;

    @include rudolf-scrollbars;

  }

  .bottom-area {
    position: absolute;
    width: 100%;

    height: 150px;
    bottom: 0;

    padding: 20px;

    .bottom-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}


