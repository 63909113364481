@import "FRONTEND/css/_variables.scss";



.active-fields-grid {
  column-gap: 1.143rem;
  display: grid;
  font-size: .857rem;
  grid-auto-rows: fit-content(100%);
  grid-template-columns: fit-content(200px) minmax(auto, 250px) fit-content(200px) minmax(auto, 250px);
  line-height: 1rem;
  row-gap: 1rem;

  .lead-name {
    font-weight: 600;
    font-size: 1rem;
  }

  .leadfield {
    font-weight: 500;

    &.linkedin {
      display: flex;

      .icon.has-text-light {
        height: 1rem;
        width: 1.1rem;
        color: $grey !important;
        font-size: 13px !important;
      }
    }
  }

  .leadvalue {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1.143rem;
  }

  .lead-freetext-comment {
    grid-column: 1 / 5;

    .lead-freetext-comment-area {
      white-space: break-spaces;
      background-color: #f5f5f5;
      padding: 10px;
    }
  }

  .lead-tags {
    .tags > .tag {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}


.document-link-cards {
  display: flex;

  flex-wrap: wrap;

  column-gap: 20px;
  row-gap: 10px;

  .document-link-card {
    border-radius: 5px;
    background: #F5F5F5;
    width: 21.429rem;
    padding: 10px;

    display: flex;

    .document-link-card-icon {
      width: 2.857rem;
    }

    .document-link-card-content {
      width: calc(21.429rem - 2.857rem - 25px);
      padding-left: 10px;

      .document-link-card-text {
        color: #000;
        font-size: .857rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .document-link-card-url {
        color: $grey;
        font-size: .857rem;
        font-weight: 500;

        width: 100%;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }

}


.contact-cards {
  display: flex;

  flex-wrap: wrap;

  column-gap: 20px;
  row-gap: 10px;

  .contact-card {
    border-radius: 5px;
    background: #F5F5F5;
    width: 28.571rem;
    padding: 12px 16px;

    display: flex;

    &.primary-contact {
      border: 2px solid #3b3b3b;
      padding: 10px 14px;
    }


    .contact-card-icon {
      margin-right: 20px;
    }

    .contact-card-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .contact-card-name {
        color: #000;
        font-size: 0.857rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .contact-card-position-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.857rem;
        font-weight: 500;
        color: #000;
      }

    }
  }
}

