@import "FRONTEND/css/_variables.scss";


@include media-low-res-or-big-dpi {
  .manage-leads-table-outer {
    .manage-leads-top {
      margin: 6px 10px;
    }
  }
}

@include media-high-res-or-low-dpi {
  .manage-leads-table-outer {
    .manage-leads-top {
      margin: 10px;
    }
  }
}

@include media-huge-dpi {
  .manage-leads-table-outer {
    .manage-leads-top {
      margin: 4px 10px;
    }
  }
}


@media screen and (max-width: $tablet - 1) {
  .manage-leads-table-outer {
    .manage-leads-top {
      margin: 4px 0 4px 5px;
    }
  }
}


.manage-leads-table-outer {
  overflow: hidden;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;


  //
  //
  // ████████╗ ██████╗ ██████╗
  // ╚══██╔══╝██╔═══██╗██╔══██╗
  //    ██║   ██║   ██║██████╔╝
  //    ██║   ██║   ██║██╔═══╝
  //    ██║   ╚██████╔╝██║
  //    ╚═╝    ╚═════╝ ╚═╝
  //
  //

  .manage-leads-top {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    height: 1.786rem;

    .leads-title {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 1.786rem;
    }

    .table-menu {
      display: flex;
      border-left: 1px solid #d9d9d9;
      padding-left: 5px;
      margin-left: 10px;
      column-gap: 3px;

      .table-menu-item {
        border-radius: 4px;
        padding: 0 5px;

        &:hover {
          background-color: #eaeaea;
          cursor: pointer;
        }
      }
    }

    .active-filters {
      flex-grow: 1;
      text-align: center;
    }

    @media screen and (max-width: $tablet - 1) {
      .active-filters {
        font-size: 0.857rem;
        line-height: 1rem;
      }
    }
    

  }



  //
  //
  // ██████╗  █████╗  ██████╗ ██╗███╗   ██╗ █████╗ ████████╗███████╗
  // ██╔══██╗██╔══██╗██╔════╝ ██║████╗  ██║██╔══██╗╚══██╔══╝██╔════╝
  // ██████╔╝███████║██║  ███╗██║██╔██╗ ██║███████║   ██║   █████╗
  // ██╔═══╝ ██╔══██║██║   ██║██║██║╚██╗██║██╔══██║   ██║   ██╔══╝
  // ██║     ██║  ██║╚██████╔╝██║██║ ╚████║██║  ██║   ██║   ███████╗
  // ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
  //
  //
  @include media-low-res-or-big-dpi {
    .rpagination {
      .pagination-pages {
        > div.pagination-button.prev,
        > div.pagination-button.next, {
          padding-top: 3px;
        }

      }
    }
  }

  @include media-high-res-or-low-dpi {
    .rpagination {
      .pagination-pages {
        > div.pagination-button.prev,
        > div.pagination-button.next, {
          padding-top: 3px;
        }

      }
    }
  }

  @include media-huge-dpi {
    .rpagination {
      .pagination-pages {
        > div.pagination-button.prev,
        > div.pagination-button.next, {
          padding-top: 3px;
        }
      }
    }
  }

  .rpagination {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    .pagination-per-page {
      .leads-per-page {
        display: flex;
        align-items: center;
      }
      
      .leads-per-page-dropdown {
        width: auto;
        margin-right: 14px;
        z-index: 1600;
      }

      @media screen and (max-width: $tablet - 1) {
        .leads-per-page-dropdown {
          margin-right: 0;
        }
      }

      // Tighter b-dropdown menu here
      .button { padding: calc(.5em - 1px) calc(.75em - 1px); }
      .button.is-select {
        justify-content: flex-end;
        border-radius: 4px;
      }
      .button.is-select span:first-child { text-overflow: initial; }
    }

    .pagination-pages {
      height: 30px;

      font-weight: 500;
      text-transform: uppercase;

      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap;

      > div.pagination-button {
        display: flex;
        align-items: center;
        border-radius: 4px;
        width: 30px;
        height: 30px;
        justify-content: center;
      }

      @media screen and (max-width: $tablet - 1) {
        > div.pagination-button {
          width: 20px;
        }
      }

      > div.pagination-button:hover {
        background-color: #eaeaea;
        cursor: pointer;
      }

      > div.pagination-position {
        width: 70px;
        text-align: center;
        display: flex;
        align-items: center;
        line-height: 1rem;
        justify-content: center;
        column-gap: 4px;

        > #leadsPerPageInput {
          padding-top: 0.02em;
        }
      }
    }

  }



  //
  //
  //  ██████╗ ██████╗ ██╗██████╗
  // ██╔════╝ ██╔══██╗██║██╔══██╗
  // ██║  ███╗██████╔╝██║██║  ██║
  // ██║   ██║██╔══██╗██║██║  ██║
  // ╚██████╔╝██║  ██║██║██████╔╝
  //  ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝
  //
  //

  @include media-low-res-or-big-dpi {
    .manage-leads-table-body { height: calc(100% - 1.786rem - 6px - 6px); }
  }

  @include media-high-res-or-low-dpi {
    .manage-leads-table-body { height: calc(100% - 1.786rem - 10px - 10px); }
  }

  @include media-huge-dpi {
    .manage-leads-table-body { height: calc(100% - 1.786rem - 4px - 4px); }
  }

  .manage-leads-table-body {
    position: relative;
    overflow-y: hidden;

    .rgrid-scroller {
      overflow: auto;
      height: 100%;

      @include rudolf-scrollbars;

      .rgrid {
        display: grid;
        align-items: stretch;

        #rgrid-draggable-headers {
          display: contents;
        }

        .rgrid-header {
          background-color: #fff;
          border-bottom: 2px solid #D9D9D9;
          position: sticky;
          top: 0;

          .rgrid-header-inner {
            background-color: #f6f6f6;
            font-weight: 600;
            color: #959BA3;
            border-right: 1px solid #d9d9d9;
            padding: 7px;
            height: 100%;

            .column-header {
              display: flex;
              flex-wrap: nowrap;

              .column-name {
                flex-grow: 1;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
              }

              .column-sort {
                height: 21px;

                &:hover { cursor: pointer; }
              }
            }
          }

          .grid-header-resize-handle {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            background: black;
            opacity: 0;
            width: 3px;
            cursor: e-resize;
            z-index: 1000;
          }

          .grid-header-resize-handle:hover,
          /* The following selector is needed so the handle is visible during
          resize even if the mouse isn't over the handle anymore */
          &.header--being-resized .grid-header-resize-handle {
            opacity: 0.5;
          }

          .touch-header-resize-handle {
            opacity: 1;
            position: absolute;
            right: -7px;
            top: calc(50% - 7.5px);
            width: 15px;
            height: 15px;
            z-index: 1001;
          }

          &.header--being-resized .touch-header-resize-handle {
            background: url('../../../images/table-column-resize.svg') top left no-repeat;
            background-position: top right no-repeat;
            background-size: 15px 15px;
          }

        }


        .rgrid-row {
          display: contents;

          .rgrid-main-row,
          .rgrid-subrow {
            display: contents;

            &:hover .rgrid-entry {
              background-color: #f6f6f6;
              cursor: pointer;
            }
          }


          .rgrid-entry {
            padding: 7px;
            align-self: stretch;
            background-color: white;
            overflow: hidden;

            .lead-contacts-list {
              font-size: .929rem;
              letter-spacing: -.03em;
              line-height: 18px;

              > li {
                text-overflow: ellipsis;
                white-space: nowrap;
                list-style-type: disc;
                overflow: hidden;
                list-style-position: inside;
              }
            }

            .row-collapse-button {
              height: 100%;
              width: 100%;
            }

            .sales-totals-textual {
              display:flex;
              font-size: 0.786rem;
              line-height: 1.1rem;

              & > .sales-label {
                width: 90px;
                font-weight: 500;
              }

              & > .sales-value {
                width: 100px;
                font-weight: 600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .products-cell-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              column-gap: 5px;
              row-gap: 5px;

              .products-cell-product {
                display: flex;
                flex-wrap: nowrap;

                font-weight: 600;
                text-transform: uppercase;

              }

              div.products-product-name {
                display: inline-block;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 5px;
              }
            }

            .per-product-cell-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              column-gap: 15px;
              row-gap: 10px;

              & > div { line-height: 1.2em; }

              .per-product-name,
              .per-product-text {
                display: inline-block;
                max-width: 160px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .statuses-cell-container {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              column-gap: 15px;
              row-gap: 10px;

              .status-container {
                display: flex;

                .status-color {
                  height: 32px;
                  width: 6px;
                  border-radius: 2px;
                  margin-right: 2px;
                }

                .status-product {
                  font-size: 0.857rem;
                  font-weight: 500;
                  line-height: 1.2em;

                  .status-product-name,
                  .status-status {
                    display: inline-block;
                    max-width: 120px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                }
              }
            }

          }

        }

        .rgrid-header.bulk-manage-checkbox-fixed,
        .rgrid-header.row-collapse-fixed,
        .rgrid-header.row-collapse-fixed2,
        .rgrid-header.lead-name-fixed,
        .rgrid-header.lead-name-fixed2 {
          position: sticky;
          z-index: 999;
        }

        .rgrid-entry.bulk-manage-checkbox-fixed,
        .rgrid-entry.row-collapse-fixed,
        .rgrid-entry.row-collapse-fixed2,
        .rgrid-entry.lead-name-fixed,
        .rgrid-entry.lead-name-fixed2 {
          position: sticky;
          z-index: 1;

          .lead-number {
            background-color: #f5f5f5;
            border-radius: 4px;
            padding: 1px 4px;
            color: #959ba3;
            font-weight: 500;
            font-size: 12px;
          }
        }

        .bulk-manage-checkbox-fixed { left: 0; }
        .row-collapse-fixed { left: 0; }
        .row-collapse-fixed2 { left: 40px; }
        .lead-name-fixed { left: 40px; }
        .lead-name-fixed2 { left: 80px; }

      }

    }

  }

}


@media screen and (max-width: $tablet - 1) {
  .manage-leads-table-outer {
    border-radius: 0;
  }
}


